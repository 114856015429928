import { EventType, pushEvent } from "./index";

/**
 * Rating/Review Renders
 */
export const event17 = (productName: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.rrRenders,
      eventLabel: productName,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.read,
    attributes: {
      nonInteractive: {
        nonInteraction: 1
      }
    }
  });
};
