import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import ProductFrequentlyBoughtItems from "../ProductFrequentlyBoughItems";
import AddToCartAlert from "../AddtoCartAlert";
import { event11 } from "../../analytics/event11";
import { LocalizedContext } from "../../services/LocalizedContextService";

import "./styles.scss";
interface ProductFrequentlyBoughtTogetherInterface {
  content: any;
  productLabels: any;
}

const ProductFrequentlyBoughtTogether: FunctionComponent<ProductFrequentlyBoughtTogetherInterface> = props => {
  const { productLabels, content } = props;
  const heading = productLabels?.frequentlyBoughtTogether || "";
  const setBreakPoints = {
    375: {
      centeredSlides: false,
      initialSlide: 0,
      spaceBetween: 24,
      slidesPerView: 1,
      scrollbar: {
        draggable: true
      }
    },
    768: {
      initialSlide: 0,
      spaceBetween: 24,
      slidesPerView: 1.4,
      scrollbar: {
        draggable: true
      }
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [linkToProduct, setLinkToProduct] = useState("");
  const { sanitySiteSettings } = useContext(LocalizedContext);

  useEffect(() => {
    event11(content, sanitySiteSettings?.brandName, "Product Frequently Bought Together");
  }, [content]);

  return (
    <div className="product-frequentlybought_container">
      {content && content.length ? (
        <>
          {heading ? <p className="product-frequentlybought_heading my-3">{heading}</p> : null}
          <div className="product-frequentlybought_slider h-100">
            <Swiper
              key="product"
              preloadImages={true}
              centeredSlides={true}
              centeredSlidesBounds={true}
              initialSlide={0}
              slidesPerView={"auto"}
              spaceBetween={20}
              simulateTouch={true}
              lazy={true}
              freeMode={true}
              loopedSlides={3}
              loop={false}
              scrollbar={{ draggable: true }}
              preventClicks={true}
              breakpoints={setBreakPoints}
              pagination={{
                type: "progressbar"
              }}
              modules={[Pagination, FreeMode]}
              grabCursor={true}
            >
              {content.map((item: any, index: number) => (
                <SwiperSlide
                  className="swiper-slide-animation p-2"
                  key={`slider-${index}`}
                  data-testid={`playlist-slide-${index}`}
                  style={{ width: "inherit", height: "auto" }}
                >
                  <ProductFrequentlyBoughtItems
                    item={item}
                    productLabels={productLabels}
                    setAlertMessage={setAlertMessage}
                    setShowAlert={setShowAlert}
                    setLinkToProduct={setLinkToProduct}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      ) : null}
      {showAlert && <AddToCartAlert message={alertMessage} setShowAlert={setShowAlert} linkToProduct={linkToProduct} />}
    </div>
  );
};

export default ProductFrequentlyBoughtTogether;
