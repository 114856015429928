import axios from "axios";

const API_ENDPOINT =
  typeof window !== "undefined" &&
  (window.location.origin === "http://localhost:8000"
    ? "https://sheamoisture-web-us-staging.netlify.app/.netlify/functions"
    : window?.location?.origin + "/.netlify/functions");

export const productSearchById = async (productId: string) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/salesforce-get-product-id`, {
      params: {
        productId
      }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};
