import React from "react";
import { currencyToSymbol } from "../../utils/currencyToSymbol";
import "./styles.scss";

export const Price = ({
  price,
  discountedPrice,
  promoCode
}: {
  price: number;
  discountedPrice?: number;
  promoCode?: string;
}) => {
  return (
    <p className="price m-0">
      {discountedPrice ? (
        <>
          <div className="price-container">
            <span className="current-price">{currencyToSymbol(discountedPrice)}</span>
            <span className={`original-price ml-2`}>( RRP {currencyToSymbol(price)} )</span>
          </div>
          <div className="promo-code">{promoCode}</div>
        </>
      ) : price ? (
        <span className="current-price">{currencyToSymbol(price)}</span>
      ) : null}
    </p>
  );
};
