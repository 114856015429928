import { EventType, pushEvent } from "./index";

/**
 * Custom Product View
 * Product Views
 */
export const event8_prodView = (
  productId: string,
  productName: string,
  brand: string,
  price?: string,
  quantity?: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  if (!win.digitalData.product) win.digitalData.product = [];
  if (!win.digitalData.component) win.digitalData.component = [];

  win.digitalData.product.push({
    productInfo: {
      productID: productId,
      productName,
      price,
      brand,
      quantity
    }
  });

  win.digitalData.component.push({
    componentInfo: {
      componentID: "Product Details Page"
    }
  });

  pushEvent({
    eventInfo: {
      type: EventType.productInfo
    },
    category: {
      primaryCategory: EventType.other
    },
    attributes: {
      nonInteractive: {
        nonInteraction: 1
      }
    },
    subcategory: EventType.read
  });
};
