import React, { FunctionComponent } from "react";
import "./styles.scss";

interface ProductTagInterface {
  tags: string[];
}

const ProductTags: FunctionComponent<ProductTagInterface> = props => {
  const { tags } = props;

  return (
    <>
      {tags?.length ? (
        <ul className="tags-wrapper pl-0">
          {tags.map(tag => (
            <li key={tag} className="tag inline-block mr-2 mr-md-3 py-2 px-4 text-uppercase mb-2">
              {tag}
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default ProductTags;
