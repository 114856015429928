import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../../components/Layout";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SEO from "../../components/Seo";
import { LocalisedPageContext } from "../../types/PageTypes";
import Product, { ProductInterface } from "../../components/Product";
import StructuredData from "../../components/StructuredData";
import { ImageInterface, LocalizedContextInterface } from "../../types/SanityTypes";
import { event8_prodView } from "../../analytics/event8_prodView";

import "../../components/Product/styles.scss";

type ProductQueryProps = {
  sanityProduct: ProductInterface;
} & LocalizedContextInterface;

type ProductPageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String) {
    sanityProduct(upc: { eq: $_id }) {
      _id
      price
      benefitTagLine
      currency
      isNew
      name: paName
      inStock
      descriptiveTags
      _rawTags(resolveReferences: { maxDepth: 10 })
      smartProductId: upc
      shortDescription: _rawPaShortDescription
      upc
      _rawImages: _rawPaImages(resolveReferences: { maxDepth: 10 })
      category: productCategory {
        name
      }
      collection: productCollection {
        name
      }
      _rawHowToUse: _rawPaHowToUse
      _rawDescription: _rawPaShortDescription
      _rawIngredients
      slug {
        current
      }
      type: productType
      seoShortTitle
      seoShortDescription
    }
    ...LocalizedContext
  }
`;

const ProductPage = (props: PageProps<ProductQueryProps, ProductPageContext>) => {
  const language = props.pageContext.language;
  const product = props.data.sanityProduct;
  const productLandingPage = props.data.sanityProductLandingPage;
  const productLabels = props.data.sanityLabels?.productLabels;
  const { productGoesWellWith = [] } = props.pageContext;

  const productData = {
    ...product,
    productLandingPage
  };
  const { htmlLang } = useSiteMetadata(language);

  const image =
    (product._rawImages as ImageInterface[])?.length > 0 ? (product._rawImages as ImageInterface[])[0] : null;
  const imageUrl = image?.asset.url ? { url: image?.asset.url as string } : undefined;

  const imageWidth = image?.asset?.metadata?.dimensions?.width;
  const imageHeight = image?.asset?.metadata?.dimensions?.height;

  const imageMimeType = image?.asset?.mimeType;
  const pageTitle = product.name;

  useEffect(() => {
    event8_prodView(product._id, product.name, props.data.sanitySiteSettings?.brandName);
  }, [product._id, product.name, props.data.sanitySiteSettings?.brandName]);

  // const currentPagePath = `${productData.name}/${productData.slug.current}`;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"product"}
      parentPages={["Products"]}
      analyticsTagMeta={{
        promoId: "5",
        category: product?.category?.name,
        subCategory: product?.productType || product?.type,
        productId: product.smartProductId,
        upc: product.upc,
        brand: product.subBrand
      }}
    >
      {/* <BreadCrumbs currentPagePath={currentPagePath} parentPagePath={""} /> */}
      <StructuredData
        type={"Product"}
        name={product.name}
        description={product.seoShortDescription}
        slug={product.slug.current}
        image={imageUrl}
        language={htmlLang}
        data={productData}
      />
      <SEO
        title={product.seoShortTitle}
        description={product.seoShortDescription}
        imageUrl={imageUrl?.url}
        imageAlt={image?.alt}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        imageMimeType={imageMimeType}
        htmlLang={htmlLang}
      />
      <Product
        sanityProduct={product}
        sanityProductLandingPage={productLandingPage}
        productLabels={productLabels}
        productGoesWellWith={productGoesWellWith}
      />
    </Layout>
  );
};

export default ProductPage;
