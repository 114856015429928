import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { ButtonInterface } from "../../components/Button";

import { WhereToBuyInterface } from "../WhereToBuy";
import { event37 } from "../../analytics/event37";
import { event38 } from "../../analytics/event38";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { Helmet } from "react-helmet";

import "./index.scss";

type MikMakInterface = WhereToBuyInterface & ButtonInterface;

const MikMak: FunctionComponent<MikMakInterface> = ({
  smartProductId,
  productName,
  productCategoryName,
  brand,
  className,
  variant,
  form,
  wtbId
}) => {
  const { buyItNow } = useContext(LocalizedContext).sanityLabels?.productLabels || {};

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    event38(smartProductId, productName, productCategoryName, brand);
  };

  const analyticsMikmak = () => {
    // push MikMak event to GTM
    const myCallback = (e: { action: string }) => {
      if (e.action === "buy") {
        event37(e);
      }
    };
    // set the tracking method in the MikMak data layer
    // @ts-ignore
    if (!window.swnDataLayer) window.swnDataLayer = {};
    // @ts-ignore
    window.swnDataLayer.widget_callback = myCallback;
    // @ts-ignore
    window.swnDataLayer.locale = "en";
  };

  useEffect(() => {
    analyticsMikmak();
  }, []);

  return (
    <div className="mikMak">
      <Helmet>
        <script type="text/javascript">
          {`console.log('Mikmak script injected!');(function(e,d){try{var a=window.swnDataLayer=window.swnDataLayer||{};a.appId=e||a.appId,a.eventBuffer=a.eventBuffer||[],a.loadBuffer=a.loadBuffer||[],a.push=a.push||function(e){a.eventBuffer.push(e)},a.load=a.load||function(e){a.loadBuffer.push(e)},a.dnt=a.dnt!=null?a.dnt:d;var t=document.getElementsByTagName("script")[0],n=document.createElement("script");n.id="mikmak-script",n.async=!0,n.src="//wtb-tag.mikmak.ai/scripts/"+a.appId+"/tag.min.js",t.parentNode.insertBefore(n,t)}catch(e){console.log(e)}}("661eb56e41b35103de160f60", false));`}
        </script>
        <meta name="ps-key" content="1750-5db31b2e0a36b30033a103a3" />
      </Helmet>

      <button
        data-mm-wtbid={wtbId}
        type="button"
        className={`button button-${variant} button-form-${form} button-size-sm swn-tag-wtb-btn ps-widget ${className}`}
        onClick={onClick}
        // eslint-disable-next-line react/no-unknown-property
        ps-sku={smartProductId}
        data-eans={smartProductId}
        aria-label="buy it now button"
      >
        {buyItNow}
      </button>
    </div>
  );
};

export default MikMak;
