import React from "react";

export const SmartLabel = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1365.8 288.8">
      <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <path
          className="st0"
          d="M0,13.5C0,8.5,3.8,0.6,13.1,0c8.5,0,33.7,0,40.3,0 M33.5,34.4c4,0,8,0,12,0c0.8,0,1.5-0.1,2.3-0.2
		c2.1-0.6,3.2-2.2,3.2-4.5c-0.1-2.1-1.4-3.7-3.5-4.1c-0.9-0.2-1.8-0.2-2.6-0.2c-7.4,0-14.8,0-22.2,0c-0.9,0-1.9,0.1-2.8,0.2
		c-1.8,0.3-2.9,1.5-3.3,3.2c-0.7,3,1.4,5.5,4.7,5.6C25.3,34.4,29.4,34.4,33.5,34.4z M43.8,50.6c0-6-4.9-10.7-10.9-10.6
		c-5.9,0.1-10.7,4.9-10.6,10.7c0,5.8,5,10.8,10.8,10.8C39,61.4,43.8,56.5,43.8,50.6z M33,19.1c5.9,0,11.8,0,17.7,0
		c2.2,0,4.3-0.5,5.2-2.9c1.3-3.2-1-6.2-4.7-6.2c-5.2,0-10.5,0-15.7,0c-6.7,0-13.4,0-20.1,0c-3.1,0-5,1.7-5.1,4.4
		c0,2.8,2,4.6,5.3,4.6C21.3,19.1,27.1,19.1,33,19.1z M53.3,0c6.5,0,13,7.9,13,14.9c0,7.1,0.3,30.6,0.3,30.6s-5,0-7.5,0.1
		c-6.5,0.1-11.1,2.7-13.7,8.6c-1.1,2.4-2.1,5.1-2.4,15.1c-9.9,0-22,0-29.7,0S0,62.3,0,55.5c0-5,0-37.1,0-42S5.2,0.1,13.1,0.1
		C21.5,0,46.8,0,53.3,0z"
        />
        <path
          className="st0"
          d="M44.8,69c0,0,0.1-4.5,0.5-8c1-8.7,6.6-13.9,13.2-14c2.7,0,7.8,0,7.8,0c-0.4,0.9-0.6,1.3-1,2
		C61.1,57,55.1,62.8,44.8,69z"
        />
        <path
          className="st0"
          d="M126.3,26.3c1.7-2.5,4-4,6.9-4.4c5.8-0.9,11.1,3,11.8,8.9c0.1,0.8,0.1,1.6,0.1,2.5c0,5.1,0,10.2,0,15.5
		c-1.1,0-2.1,0-3.3,0c0-0.6,0-1.2,0-1.9c0-4.9,0-9.9,0-14.8c0-3.2-1.7-5.6-4.5-6.5c-2.9-1-6.2-0.4-7.8,2c-0.9,1.2-1.5,2.9-1.5,4.3
		c-0.2,4.9-0.1,9.9-0.1,14.8c0,0.6,0,1.3,0,2.1c-1.1,0-2,0-3.2,0c0-0.6,0-1.3,0-1.9c0-4.9,0-9.9,0-14.8c0-2.7-1.2-4.9-3.7-6.2
		c-2.6-1.2-5.1-1.1-7.5,0.5c-1.7,1.1-2.6,2.7-2.6,4.7c-0.1,5.1-0.1,17.6-0.1,17.6h-3.5c0,0-0.1-11.3,0-16.3c0.1-4.4,1.9-7.9,6.1-9.7
		c3.9-1.7,7.6-1.1,10.9,1.5C125,24.7,125.6,25.5,126.3,26.3z"
        />
        <path
          className="st0"
          d="M255.9,35.4c0,6.1,3.4,10.3,9.7,10.3c5.3,0,9.7-4.7,9.7-10.3c-0.1-5.8-4.4-10.4-9.8-10.4
		C260.2,25.1,255.9,29.4,255.9,35.4z M255.9,11.5c0,4.8,0,10.6,0,15.1c2.4-2.8,4.5-3.8,6.9-4.5c6.8-1.9,13.9,3.2,15.5,10.6
		c1.7,8.2-4.5,16.6-13.3,16.4c-5.9-0.1-12.3-4.8-12.3-11.7c-0.3-8.6-0.1-17.1-0.1-25.9C253.6,11.5,254.6,11.5,255.9,11.5z"
        />
        <path
          className="st0"
          d="M305.6,33.6c-0.5-4.7-5-8.4-10.4-8.4s-9.6,4-9.6,8.4C292.2,33.6,298.8,33.6,305.6,33.6z M308.8,36.7
		c-7.8,0-15.6,0-23.5,0c0.2,2.8,1.3,5.1,3.3,6.6c5.4,4,13.3,2.8,16.3-3.7c0.1-0.2,0.2-0.8,0.3-1h3.2c-0.7,5.1-4.9,10.4-13.3,10.4
		c-9.6,0-13.8-8.2-13.3-14.5c0.5-6.7,4.9-12.9,13.5-12.9C304,21.7,309.9,29.7,308.8,36.7z"
        />
        <path
          className="st0"
          d="M171.8,35.7c0-5.9-4.4-10.6-10-10.6c-5.4,0-10.1,4.9-9.9,10.7c0.1,5.7,4,10.4,10.1,10.4
		C167.4,46.3,171.8,41.5,171.8,35.7z M171.9,48.7v-3.8c0,0-2.4,4.6-10,4.6c-8.4,0-13.2-7-13.2-14.4s5.7-13.5,13.2-13.5
		s13,6,13.2,12.7c0.1,4.5,0,14.3,0,14.3h-3.2V48.7z"
        />
        <path
          className="st0"
          d="M245.4,35.3c0-5.3-4-10.2-9.7-10.2c-5.4,0-9.7,4.6-9.7,10.3c0,5.7,4.4,10.5,9.8,10.4
		C242.1,45.6,245.4,40.7,245.4,35.3z M248.7,48.7h-3.2c0,0,0-2.8,0-3.9c-3.2,4.3-8.4,4.2-9.7,4.2c-5.9,0-13-4.6-13-13.7
		s7.4-13.7,13.3-13.7c5.9,0,12.6,5.8,12.6,11.6C248.6,39.1,248.7,48.7,248.7,48.7z"
        />
        <path
          className="st0"
          d="M102,25.1c-0.7,0.8-1.3,1.5-2,2.4c-2-2.2-4.4-2.7-7-2.3c-1.1,0.1-2.3,0.5-3.2,1.2c-1.8,1.3-1.6,4,0.2,5.1
		c1.8,1,3.7,1.6,5.6,2.4c1.7,0.7,3.4,1.3,5,2.1c5.1,2.7,4,8.9,0.4,11.6c-3.9,2.9-13,2.8-16.5-2.9c0.7-0.6,1.5-1.2,2.4-1.8
		c2.1,2.5,4.8,3.8,7.9,3.5c1.4-0.1,2.6-0.5,3.8-1.3c2.5-1.5,2.7-4.8,0.2-6.2c-1.5-0.9-3.2-1.4-4.9-2c-1.9-0.8-3.9-1.4-5.6-2.4
		c-3.8-2.3-4.2-7-0.9-10.1C91.2,20.6,98.5,21,102,25.1z"
        />
        <path
          className="st0"
          d="M214.7,11.5c0,0,0,19.5,0,28.3c0,4.2,1.5,5.8,5.7,5.8c0.2,0,0.4,0,0.8,0c0,1.1,0,2.1,0,3
		c-6.1,1.1-9.6-3.2-9.7-8.1c-0.2-9.3-0.1-29.1-0.1-29.1L214.7,11.5L214.7,11.5z"
        />
        <path
          className="st0"
          d="M200,39.8c0,4.2,1.5,5.8,5.7,5.8c0.2,0,0.4,0,0.8,0c0,1.1,0,2.1,0,3c-6.1,1.1-9.6-3.2-9.7-8.1V17.1h3.3v5.2
		h6.4v3.5H200V39.8z"
        />
        <path
          className="st0"
          d="M323,45.5v3.3c0,0-2.3,0.1-2.9,0c-5.2-0.5-6.6-3.1-6.7-8c-0.2-9.5,0-29.4,0-29.4h3.2c0,0,0,19.5,0,28.2
		c0,4.3,0.5,5.6,4.9,5.8L323,45.5L323,45.5z"
        />
        <path
          className="st0"
          d="M192.7,25.1c0,0-2.4-0.1-3.5,0c-3.9,0.3-6.6,3-6.6,6.9c-0.1,4.9,0,16.7,0,16.7h-3.2c0,0-0.1-11.4,0-16.5
		c0.2-6.8,5.2-11,12.3-10.3c0.6,0.1,1.1,0.2,1.1,0.2v3H192.7z"
        />
        <path className="st0" d="M322,16.3h-0.7v-3.1H320v-0.6h3.2v0.6H322V16.3z" />
        <path
          className="st0"
          d="M325.9,15.4L325.9,15.4l-1.2-1.7v2.6H324v-3.7h0.7l1.2,1.7l1.2-1.7h0.7v3.7h-0.7v-2.6L325.9,15.4z"
        />
      </g>
    </svg>
  );
};
