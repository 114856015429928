import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "../Link";
import Button from "../Button";
import { useMutation } from "react-query";
import { productSearchById } from "../../services/salesforce/Product";
import { addProduct } from "../../services/salesforce/Bag";
import EcommerceContext from "../../services/EcommerceContextService";
import LoaderIcon from "../../images/icons/loader";
import SanityImage from "gatsby-plugin-sanity-image";
import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

interface ProductFrequentlyBoughtItemsInterface {
  item: any;
  productLabels: any;
  setAlertMessage: (alertMessaage: string) => void;
  setShowAlert: (showAlert: boolean) => void;
  setLinkToProduct: (linkToProduct: string) => void;
}

const ProductFrequentlyBoughtItems: FC<ProductFrequentlyBoughtItemsInterface> = props => {
  const [itemOrderable, setItemOrderable] = useState(false);
  const { setBagItems } = useContext(EcommerceContext);
  const { item, productLabels, setAlertMessage, setShowAlert, setLinkToProduct } = props;

  const { isLoading, mutate: searchProduct } = useMutation({
    mutationFn: productSearchById,
    retry: 1,
    onSuccess: response => {
      const { data = {} } = response || {};
      if (data && data.id) {
        setItemOrderable(data.inventory.orderable);
      }
    },
    mutationKey: item._id
  });

  useEffect(() => {
    searchProduct(item._id);
  }, []);

  const handleAddToProduct = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const response = await addProduct(item._id);
      const { quantity, title, basketId } = response?.data;
      if (basketId) {
        setBagItems(response?.data);
        setAlertMessage("Product Added to the Bag");
      }
      if (quantity && title) {
        setAlertMessage(title);
      }
    } catch (err) {
      setAlertMessage("Failed to add product to the Bag");
      console.log(err);
    } finally {
      setLinkToProduct(item.slug.current);
      setShowAlert(true);
    }
  };

  return (
    <Link
      to={item.slug.current}
      className="product-frequentlybought_slider-item d-flex justify-content-between shadow p-2 py-3 bg-white text-decoration-none w-100 h-100"
    >
      {item.images[0] && (
        <SanityImage
          asset={item.images[0].asset}
          width={90}
          height={90}
          htmlWidth={90}
          htmlHeight={90}
          className="img-fluid"
          alt={item.images[0].alt}
          sizes={IMAGE_SIZES.TINY_SQUARE}
        />
      )}
      <div className="d-flex flex-column justify-content-between">
        <div>
          <p className="product-frequentlybought_slider_name mb-2 text-dark">{item.name}</p>
          <span className="product-frequentlybought_slider_price">${item.price}</span>
        </div>
        {/* <div className="bin-wrapper mt-2">
          <Button
            className="product-frequentlybought_slider_button text-uppercase px-5 py-3"
            variant="septenary"
            form="br-50"
            size="md"
            disabled={!itemOrderable}
            title={!itemOrderable ? `Product ${item.name} is not orderable` : "Click to add product"}
            onClick={handleAddToProduct}
          >
            {itemOrderable ? <span>+ {productLabels.addToBag}</span> : <span>{productLabels.soldOut}</span>}
            {isLoading && <LoaderIcon width={25} height={25} className="loaderIcon" />}
          </Button>
        </div> */}
      </div>
    </Link>
  );
};

export default ProductFrequentlyBoughtItems;
