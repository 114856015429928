import { EventType, pushEvent } from "./index";

/**
 * Product Listing Impressions
 */
export const event11 = (
  content: {
    _id: string;
    name: string;
  }[],
  brand: string,
  componentName: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  if (!win.digitalData.product) win.digitalData.product = [];
  if (!win.digitalData.component) win.digitalData.component = [];
  content.forEach((product, index) => {
    win.digitalData.product.push({
      productInfo: {
        productID: product?._id,
        productName: product?.name,
        brand
      },
      attributes: {
        listPosition: index
      }
    });

    win.digitalData.component.push({
      componentInfo: {
        componentID: componentName
      },
      attributes: {
        position: index,
        listPosition: index
      }
    });
  });

  pushEvent({
    eventInfo: {
      type: EventType.productImpression
    },
    category: {
      primaryCategory: EventType.custom
    },
    attributes: {
      nonInteractive: {
        nonInteraction: 1
      }
    },
    subcategory: EventType.lead
  });
};
