import { EventType, pushEvent } from "./index";

/**
 * Rating/Review Submit
 */
export const event20 = (productName: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.ratingreview,
      eventLabel: productName,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    }
  });
};
