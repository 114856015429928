import { EventType, pushEvent } from "./index";

/**
 * Rating/Review Close
 */
export const event19 = (productName: string) => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.rrClose,
      eventLabel: productName,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.interest
  });
};
