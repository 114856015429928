import React, { FunctionComponent, useEffect, useRef } from "react";
import { event17 } from "../../analytics/event17";
import { event18 } from "../../analytics/event18";
import { event19 } from "../../analytics/event19";
import { event20 } from "../../analytics/event20";

interface BazaarvoiceReviewsInterface {
  upc: string;
  productName: string;
  label?: string;
}

const BazaarvoiceReviews: FunctionComponent<BazaarvoiceReviewsInterface> = ({ upc, productName, label }) => {
  const bvReviews = useRef<HTMLDivElement>(null);

  useEffect(() => {
    showReviews();
  }, []);

  const submitReviewClickHandler = () => {
    const numErrors = Array.from(document.querySelectorAll(".bv-helper-icon-negative")).filter(
      icon => window.getComputedStyle(icon).display !== "none"
    ).length;
    if (numErrors === 0) {
      event20(productName);
    }
  };

  const writeReviewOpenClickHandler = () => {
    event18(productName);
    const waitForReviewForm = setInterval(() => {
      const submitButton = document.querySelector("[name=bv-submit-button]") as HTMLButtonElement;
      if (submitButton) {
        clearInterval(waitForReviewForm);

        submitButton.addEventListener("click", submitReviewClickHandler);

        const closeButton = document.querySelector(".bv-mbox-close") as HTMLButtonElement;
        const closeButtonListener = () => {
          event19(productName);
          closeButton.removeEventListener("click", closeButtonListener);
        };
        closeButton.addEventListener("click", closeButtonListener);
      }
    }, 500);
  };

  const showReviews = () => {
    bvReviews.current?.setAttribute("data-bv-show", "reviews");
    bvReviews.current?.setAttribute("data-bv-product-id", upc);
    event17(productName);

    const waitForReviews = setInterval(() => {
      const writeReviewButtons = document.querySelectorAll(
        ".bv-write-review, .bv-write-review-label"
      ) as NodeListOf<HTMLButtonElement>;
      if (!(writeReviewButtons.length === 0)) {
        clearInterval(waitForReviews);
        writeReviewButtons.forEach(writeReviewButton => {
          writeReviewButton.addEventListener("click", writeReviewOpenClickHandler);
        });
      }
    }, 500);
  };

  return (
    <div data-testid="product-reviews" className="rx-reviews">
      <h2>{label}</h2>
      <div id="bazaarvoice-reviews" ref={bvReviews} />
    </div>
  );
};

export default BazaarvoiceReviews;
