import React, { FunctionComponent, useEffect } from "react";
import "./styles.scss";
import { Link } from "../Link";

interface AddToCartAlertInterface {
  setShowAlert: (showAlert: boolean) => void;
  message: string;
  linkToProduct?: string;
  needBagLink?: boolean;
}

const AddToCartAlert: FunctionComponent<AddToCartAlertInterface> = props => {
  const { setShowAlert, message, linkToProduct, needBagLink = true } = props;

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }, []);

  return (
    <>
      {linkToProduct ? (
        <Link to={linkToProduct} className="add-to-cart-alert add-to-cart-alert__product-link">
          <div className="add-to-cart-alert__message">{message}</div>
          {needBagLink ? (
            <Link to="/bag" className="add-to-cart-alert__link">
              See the shopping bag
            </Link>
          ) : null}
        </Link>
      ) : (
        <div className="add-to-cart-alert">
          <div className="add-to-cart-alert__message">{message}</div>
          {needBagLink ? (
            <Link to="/bag" className="add-to-cart-alert__link">
              See the shopping bag
            </Link>
          ) : null}
        </div>
      )}
    </>
  );
};

export default AddToCartAlert;
