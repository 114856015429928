import React, { useContext } from "react";
import Helmet from "react-helmet";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContext } from "../../services/LocalizedContextService";

const BazaarvoiceScript = () => {
  const { language } = useContext(LocalizedContext);
  const { locale, bazaarvoiceEnv } = useSiteMetadata(language);
  return (
    <>
      {typeof window !== "undefined" ? (
        !window.$BV ? (
          <Helmet>
            <link rel="preconnect" href="https://network.bazaarvoice.com" />
            <link rel="preconnect" href="https://analytics-static.ugc.bazaarvoice.com" />
            <script
              defer
              src={`https://apps.bazaarvoice.com/deployments/sheamoisture/main_site/${bazaarvoiceEnv}/${locale}/bv.js`}
            />
          </Helmet>
        ) : null
      ) : null}
    </>
  );
};

export default BazaarvoiceScript;
