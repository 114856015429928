import React, { useContext, useEffect, useRef, useState } from "react";
// React Bootstrap
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
// Sanity
import {
  ImageInterface,
  PortableText,
  SanityCategory,
  SanityProductLabelsInterface,
  Slug
} from "../../types/SanityTypes";

// Components
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
// import { ProductQuantity } from "../ProductQuantity";
import ContextAwareAccordionToggle from "../ContextAwareAccordionToggle";
import Ingredients from "../Ingredients";
// Rating & Reviews
import ProductRatingsAndReviewsScript from "../ProductRatingsAndReviewsScript";
import ProductRatings from "../ProductRatings";
import ProductReviews from "../ProductReviews";

// BIN
import { LabelNew } from "../../images/icons/label-new";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y, Lazy, Navigation, Pagination, Thumbs } from "swiper";

// Component Styles
// import SwiperClass from "swiper";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { Price } from "../Price";
// import Subscription from "../Subscription";
import ProductTags from "../ProductTags";
import ProductReinvestment from "../ProductReinvestment";
import ProductFrequentlyBoughtTogether from "../ProductFrequentlyBoughtTogether";
import SanityImage from "gatsby-plugin-sanity-image";
import { addProduct } from "../../services/salesforce/Bag";
import AddToCartAlert from "../AddtoCartAlert";
import EcommerceContext from "../../services/EcommerceContextService";
import { useQuery } from "react-query";
import { productSearchById } from "../../services/salesforce/Product";
import { StringParam, useQueryParam } from "use-query-params";
import { scAddEvent } from "../../analytics/scAddProduct";

import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";
import MikMak from "../MikMak";

export type ProductComponentInterface = {
  sanityProduct: ProductInterface;
  sanityProductLandingPage?: Slug;
} & SanityProductLabelsInterface &
  [];

export type ProductInterface = {
  _id: string;
  price: number;
  promotions?: {
    id: string;
    name: string;
    price: number;
  };
  subBrand: {
    name: string;
    format: string;
  };
  variant?: {
    eVariantName?: string;
  };
  benefitTagLine: string;
  _rawDescription?: PortableText;
  _rawHowToUse?: PortableText;
  _rawImages: ImageInterface[];
  _rawIngredients?: PortableText;
  _rawPerfectFor?: PortableText;
  _rawTryThis?: PortableText;
  category: SanityCategory[];
  currency: string;
  collection?: {
    name?: string;
  };
  paRetailerCategory?: string;
  displayReviews: boolean;
  name: string;
  seoShortTitle: string;
  seoShortDescription: string;
  shortDescription: string;
  paSize: string;
  tags: string[];
  slug: {
    current: string;
  };
  smartLabelId?: string;
  smartProductId?: string;
  upc?: string;
  isNew?: boolean;
};

export const Product = (props: any) => {
  const { language, sanitySiteSettings } = useContext(LocalizedContext);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [addToBagClick, handleAddToBagClick] = useState(false);
  const { locale, sanityId, sanityDataset } = useSiteMetadata(language);
  const productLabels = props.productLabels;
  const productGoesWellWith = props.productGoesWellWith;
  const product = props.sanityProduct;
  const [alertMessaage, setAlertMessage] = useState("");
  const { setBagItems } = useContext(EcommerceContext);
  const [showAlert, setShowAlert] = useState(false);
  const [itemOrderable, setItemOrderable] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoDiscountPrice, setPromoDiscountPrice] = useState(0);
  const [qty, setQty] = useState(1);
  const deliverySubscription = productLabels?.deliverySubscription;
  const [isSubscriptionChecked, setSubscriptionChecked] = useState(false);
  const [subscriptionValue, setSubscriptionValue] = useState(
    deliverySubscription && deliverySubscription?.frequency.length ? deliverySubscription.frequency[0].value : ""
  );
  const [enableAnalyticsParam] = useQueryParam("enableAnalytics", StringParam);
  const [enableAnalytics, setEnableAnalytics] = useState(false);
  const buttonRef = useRef<any>(null);

  const { data, error, isLoading, refetch } = useQuery({
    queryFn: () => productSearchById(product.id || product.upc),
    staleTime: Infinity,
    onSuccess: response => {
      const { data = {} } = response || {};
      if (data && data.id) {
        setItemOrderable(data.inventory.orderable);
        if (data.productPromotions && data.productPromotions.length) {
          setPromoCode(data.productPromotions[0].calloutMsg);
          setPromoDiscountPrice(data.productPromotions[0].promotionalPrice);
        }
      }
    }
  });

  useEffect(() => {
    setEnableAnalytics(enableAnalyticsParam != "false");
  }, [enableAnalyticsParam]);

  useEffect(() => {
    refetch();
    !product._rawImages.length && product._rawImages.push(sanitySiteSettings._rawProductFallbackImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SWIPER
  SwiperCore.use([Lazy, Pagination, A11y, Navigation, Thumbs]);

  const carouselSlides = (
    image: { alt: string | undefined; asset: ImageInterface },
    index: React.Key | null | undefined,
    thumbs: boolean
  ) => {
    return (
      <SwiperSlide key={index}>
        <div>
          {thumbs ? (
            <SanityImage
              asset={image.asset}
              width={76}
              height={76}
              htmlWidth={76}
              htmlHeight={76}
              className="img-fluid"
              alt={image.alt}
              sizes={IMAGE_SIZES.HALF}
            />
          ) : (
            <img
              src={`${image.asset.url}?w=600&amp;h=600&amp;q=75&amp;fit=max&amp;auto=format`}
              srcSet={`${image.asset.url}?w=600&amp;h=600&amp;q=75&amp;fit=max&amp;auto=format&amp;dpr=0.5 300w, ${image.asset.url}?w=600&amp;h=600&amp;q=75&amp;fit=max&amp;auto=format&amp;dpr=0.75 450w, ${image.asset.url}?w=600&amp;h=600&amp;q=75&amp;fit=max&amp;auto=format 600w, ${image.asset.url}?w=600&amp;h=600&amp;q=75&amp;fit=max&amp;auto=format&amp;dpr=1.5 900w, ${image.asset.url}?w=600&amp;h=600&amp;q=75&amp;fit=max&amp;auto=format&amp;dpr=2 1200w`}
              className="img-fluid css-1xdhyk6"
              alt={image.alt}
              sizes="(max-width: 760px) 100vw, 50vw"
              width="600"
              height="600"
            />
          )}
        </div>
      </SwiperSlide>
    );
  };

  // Thumbnail Swiper
  const thumbSwiper = () => {
    return (
      <>
        <Swiper
          lazy={true}
          speed={700}
          threshold={5}
          preloadImages={true}
          navigation={false} // set 'true' for navigation arrows
          spaceBetween={6}
          slidesPerView={7}
          freeMode={true}
          // watchSlidesProgress={true}
          onSwiper={setThumbsSwiper as any}
        >
          {product._rawImages &&
            product._rawImages.length > 0 &&
            product._rawImages.map((slide: any, index: number) => {
              return carouselSlides(slide, index, true);
            })}
        </Swiper>
      </>
    );
  };

  const handleAddToBag = async () => {
    let c_SorWeekInterval;
    let c_SorMonthInterval;
    let c_SorPeriodicity = "";
    let c_hasSmartOrderRefill = false;
    if (isSubscriptionChecked) {
      c_hasSmartOrderRefill = isSubscriptionChecked;
      const interval = Number(subscriptionValue.replace(/[^0-9]/gi, "")) || 1;
      c_SorPeriodicity = subscriptionValue.replace(/[^a-zA-Z]/gi, "") === "m" ? "month" : "week";
      if (c_SorPeriodicity === "month") {
        c_SorMonthInterval = interval;
        c_SorWeekInterval = 0;
      } else {
        c_SorWeekInterval = interval;
        c_SorMonthInterval = 0;
      }
    }
    try {
      const response = await addProduct(
        product._id || product.upc,
        qty,
        c_hasSmartOrderRefill,
        c_SorPeriodicity,
        c_SorMonthInterval,
        c_SorWeekInterval
      );
      const { quantity, title, basketId } = response?.data;
      if (basketId) {
        setBagItems(response?.data);
        setAlertMessage("Product Added to the Bag");
      }
      if (quantity && title) {
        setAlertMessage(title);
      }
      handleAddToBagClick(!addToBagClick);
    } catch (err) {
      console.log(err);
      setAlertMessage("Failed to add product to the Bag");
    } finally {
      setShowAlert(true);
    }
  };

  useEffect(() => {
    addToBagClick &&
      setTimeout(() => {
        handleAddToBagClick(false);
        buttonRef && buttonRef.current && buttonRef.current.blur();
      }, 5000);
  }, [addToBagClick]);

  return (
    <section className="rx-product">
      {enableAnalytics && <ProductRatingsAndReviewsScript />}
      <Container fluid>
        <div className="grid-row specs">
          <div className="grid-col grid-col-md-6">
            <div className={`rx-pdp-grid__content sticky-ponel`}>
              <div className={`rx-product-carousel ${product._rawImages?.length === 1 ? "is-only-item" : ""}`}>
                <Swiper
                  spaceBetween={0}
                  speed={700}
                  threshold={5}
                  preloadImages={true}
                  navigation={product._rawImages?.length !== 1}
                  thumbs={{ swiper: thumbsSwiper }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    768: {
                      slidesPerView: 1
                    }
                  }}
                >
                  {product._rawImages &&
                    product._rawImages.length > 0 &&
                    product._rawImages.map((slide: any, index: number) => {
                      return carouselSlides(slide, index, false);
                    })}
                </Swiper>
              </div>
              <div className="rx-product-carousel-thumbs">{product._rawImages?.length > 1 ? thumbSwiper() : null}</div>
            </div>
          </div>
          <div className="grid-col grid-col-md-6 title-and-description">
            <div className="content rx-pdp-grid__content">
              <div className="benefit-tag">{product.benefitTagLine}</div>
              <div className="product-name">
                {product.isNew && <LabelNew />}
                <h1>{product.name}</h1>
              </div>
              {(product.smartProductId || product.upc) && enableAnalytics && (
                <div className="ratings">
                  <ProductRatings upc={product.upc} productName={product.name} summary={true} template="detail" />
                </div>
              )}
              <ProductTags tags={product.descriptiveTags} />
              <Price price={product.price} discountedPrice={promoDiscountPrice} promoCode={promoCode} />
              {/* <Subscription
                {...deliverySubscription}
                setSubscriptionChecked={setSubscriptionChecked}
                isSubscriptionChecked={isSubscriptionChecked}
                setSubscriptionValue={setSubscriptionValue}
                subscriptionValue={subscriptionValue}
              /> */}

              {/* SECTION: ADD TO CART  */}

              <div className="bin-wrapper">
                <div className="add-to-cart__wrapper">
                  <MikMak
                    brand={sanitySiteSettings.brandName}
                    smartProductId={product.smartProductId || product.upc}
                    productName={product.name}
                    productCategoryName={product.category || product.paRetailerCategory || ""}
                    className={`button-size-lg button-size-lg-responsive add-to-cart`}
                    variant="septenary"
                    form="br-50"
                    wtbId="661ec37041b35103de160f66"
                  />

                  {/* <ProductQuantity qty={qty} setQty={setQty} />*/}

                  {/* <button
                    ref={buttonRef}
                    className={`button button-septenary button-size-lg button-form-br-50 button-size-lg-responsive add-to-cart ${
                      itemOrderable ? "" : "add-to-cart__disabled"
                    } ${addToBagClick ? "added" : ""}`}
                    disabled={!itemOrderable}
                    onClick={() => {
                      //@ts-ignore
                      handleAddToBag(),
                        scAddEvent(
                          `${product.smartProductId}`,
                          product.name,
                          product.category || product.paRetailerCategory,
                          sanitySiteSettings?.brandName,
                          promoDiscountPrice || product.price,
                          qty,
                          "PDP page"
                        );
                    }}
                  >
                    {itemOrderable
                      ? addToBagClick
                        ? productLabels.added
                        : productLabels.addToBag
                      : productLabels.outOfStock}
                  </button>
                  <ProductReinvestment reinvestmentMsgLabel={productLabels.reinvestmentMsgLabel} /> */}
                </div>
              </div>

              {/* {showAlert && <AddToCartAlert message={alertMessaage} setShowAlert={setShowAlert} />}  */}

              <ProductFrequentlyBoughtTogether content={productGoesWellWith} productLabels={productLabels} />
              <div className="grid-row details">
                <div className="gris-col grid-col-12">
                  <Accordion>
                    {product._rawDescription && (
                      <Card data-testid="product-description">
                        <ContextAwareAccordionToggle eventKey="description" accordianTitle={productLabels?.details}>
                          <h2>{productLabels?.details}</h2>
                        </ContextAwareAccordionToggle>
                        <Accordion.Collapse eventKey="description">
                          <Card.Body>
                            <BlockContent blocks={product._rawDescription} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                    {(product._rawIngredients || locale.split("_")[1] !== "US") && (
                      <Card data-testid="product-ingredients">
                        <ContextAwareAccordionToggle eventKey="ingredients" accordianTitle={productLabels?.ingredients}>
                          <h2>{productLabels?.ingredients}</h2>
                        </ContextAwareAccordionToggle>
                        <Accordion.Collapse eventKey="ingredients">
                          <Card.Body>
                            <p>{productLabels?.ingredientDisclaimerLabel}</p>
                            <Ingredients
                              text={product._rawIngredients as unknown as PortableText}
                              smartLabelId={product.smartLabelId}
                            />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                    {product._rawHowToUse && (
                      <Card data-testid="product-how-to-use">
                        <ContextAwareAccordionToggle eventKey="how-to-use" accordianTitle={productLabels?.howTo}>
                          <h2>{productLabels?.howTo}</h2>
                        </ContextAwareAccordionToggle>
                        <Accordion.Collapse eventKey="how-to-use">
                          <Card.Body>
                            <BlockContent blocks={product._rawHowToUse} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                  </Accordion>
                </div>
              </div>
              {/* {product.slug && (
                <div style={{ minWidth: "100px" }}>
                  <SocialShare
                    url={`${`https://www.sheamoisture.com/`}${product.slug.current}/`}
                    locale={locale}
                    expanded={true}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </Container>
      <section className="rx-section--rating-reviews">
        <Container>
          <div className="grid-row">
            <div className="grid-col">
              {(product.smartProductId || product.upc) && enableAnalytics && (
                <ProductReviews
                  upc={product.upc || product.smartProductId}
                  productName={product.name}
                  label={productLabels?.reviews}
                />
              )}
            </div>
          </div>
        </Container>
      </section>
    </section>
  );
};

export default Product;
