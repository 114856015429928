import React, { FunctionComponent } from "react";
import { PortableText } from "../../types/SanityTypes";
import { SmartLabel } from "../../images/icons/smartLabel";
import Accessibility from "../Accessibility";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";

export interface IngredientsInterface {
  text?: PortableText;
  smartLabelId?: string;
}

const Ingredients: FunctionComponent<IngredientsInterface> = ({ text, smartLabelId }) => {
  const { smartLabel } = useSiteMetadata();

  return (
    <div data-testid="ingredients" className="ingredients">
      {smartLabel === true && smartLabelId ? (
        <a
          href={`https://smtlbl.app/product/${smartLabelId}`}
          rel="noopener noreferrer"
          data-analytics-event48=""
          data-testid="smart-label-link"
        >
          <SmartLabel />
          <Accessibility location={"SmartLabel"} openNewWindow={true} />
        </a>
      ) : (
        <div data-testid="ingredients-text" className="ingredients-text">
          <BlockContent serializers={RichTextSerializers()} blocks={text} />
        </div>
      )}
    </div>
  );
};

export default Ingredients;
